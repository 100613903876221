var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "js-common-wrap js-creater-user" }, [
    _c("div", { staticClass: "js-common-head" }, [
      _c(
        "div",
        [
          _c("el-button", {
            staticClass: "back-btn",
            attrs: {
              icon: "el-icon-arrow-left",
              size: "small",
              type: "primary",
              plain: "",
            },
            on: { click: _vm.$goBack },
          }),
          _c("label", { staticClass: "label" }, [
            _vm._v(_vm._s(_vm.type ? "修改用户" : "创建用户")),
          ]),
        ],
        1
      ),
    ]),
    _c("div", { staticClass: "js-common-content" }, [
      _c("div", { staticClass: "js-creater-user-content" }, [
        _c("ul", [
          _c(
            "li",
            [
              _c("label", [_vm._v("头像：")]),
              _c(
                "el-upload",
                {
                  staticClass: "avatar-uploader",
                  attrs: {
                    action: "#",
                    "show-file-list": false,
                    "auto-upload": false,
                    "on-change": _vm.handleHttpRequest,
                  },
                },
                [
                  _vm.params.avatar
                    ? _c("img", {
                        staticClass: "image-url",
                        attrs: { src: _vm.params.imageUrl },
                      })
                    : _c("div", { staticClass: "icon" }, [
                        _c("img", {
                          attrs: {
                            src: require("@/assets/images/13.png"),
                            alt: "",
                          },
                        }),
                        _c("p", [_vm._v("上传图片")]),
                      ]),
                ]
              ),
            ],
            1
          ),
          _c(
            "li",
            [
              _vm._m(0),
              _c("el-input", {
                attrs: { size: "small", disabled: "" },
                model: {
                  value: _vm.params.tenantCode,
                  callback: function ($$v) {
                    _vm.$set(_vm.params, "tenantCode", $$v)
                  },
                  expression: "params.tenantCode",
                },
              }),
            ],
            1
          ),
          _c(
            "li",
            [
              _vm._m(1),
              _c("el-input", {
                attrs: { size: "small" },
                model: {
                  value: _vm.params.name,
                  callback: function ($$v) {
                    _vm.$set(_vm.params, "name", $$v)
                  },
                  expression: "params.name",
                },
              }),
            ],
            1
          ),
          _c(
            "li",
            [
              _vm._m(2),
              _c("el-input", {
                attrs: { size: "small" },
                model: {
                  value: _vm.params.realName,
                  callback: function ($$v) {
                    _vm.$set(_vm.params, "realName", $$v)
                  },
                  expression: "params.realName",
                },
              }),
            ],
            1
          ),
          _c(
            "li",
            [
              _c("label", [_vm._v("密码：")]),
              _c("el-input", {
                attrs: { size: "small", "show-password": "" },
                model: {
                  value: _vm.params.password,
                  callback: function ($$v) {
                    _vm.$set(_vm.params, "password", $$v)
                  },
                  expression: "params.password",
                },
              }),
            ],
            1
          ),
          _c("li", [
            _vm._m(3),
            _c(
              "div",
              [
                _c(
                  "el-select",
                  {
                    staticClass: "line",
                    attrs: {
                      placeholder: "请选择",
                      size: "small",
                      clearable: "",
                    },
                    model: {
                      value: _vm.params.roleId,
                      callback: function ($$v) {
                        _vm.$set(_vm.params, "roleId", $$v)
                      },
                      expression: "params.roleId",
                    },
                  },
                  _vm._l(_vm.options, function (item) {
                    return _c("el-option", {
                      key: item.id,
                      attrs: { label: item.name, value: item.id },
                    })
                  }),
                  1
                ),
                _c("p", { staticClass: "tip" }, [
                  _vm._v(
                    "通讯列表显示眼镜用户加普通用户；可查看可创建作业流程，可改自己创建的流程，可执行和上传作业；无法删除记录"
                  ),
                ]),
              ],
              1
            ),
          ]),
          _c(
            "li",
            [
              _c("label", [_vm._v("联系方式：")]),
              _c("el-input", {
                attrs: { size: "small" },
                model: {
                  value: _vm.params.mobile,
                  callback: function ($$v) {
                    _vm.$set(_vm.params, "mobile", $$v)
                  },
                  expression: "params.mobile",
                },
              }),
            ],
            1
          ),
          _c("li", [
            _c("label", [_vm._v("组织：")]),
            _c(
              "div",
              { staticClass: "flex1" },
              [
                _c(
                  "el-select",
                  {
                    staticClass: "half",
                    attrs: {
                      placeholder: "请选择",
                      size: "small",
                      clearable: "",
                    },
                    on: { change: _vm.deptChnage },
                    model: {
                      value: _vm.params.deptParentId,
                      callback: function ($$v) {
                        _vm.$set(_vm.params, "deptParentId", $$v)
                      },
                      expression: "params.deptParentId",
                    },
                  },
                  _vm._l(_vm.deptArr, function (item) {
                    return _c("el-option", {
                      key: item.id,
                      attrs: { label: item.name, value: item.id },
                    })
                  }),
                  1
                ),
                _c(
                  "el-select",
                  {
                    staticClass: "select half",
                    attrs: {
                      placeholder: "请选择",
                      size: "small",
                      clearable: "",
                    },
                    model: {
                      value: _vm.params.deptId,
                      callback: function ($$v) {
                        _vm.$set(_vm.params, "deptId", $$v)
                      },
                      expression: "params.deptId",
                    },
                  },
                  _vm._l(_vm.subDept, function (sub) {
                    return _c("el-option", {
                      key: sub.id,
                      attrs: { label: sub.name, value: sub.id },
                    })
                  }),
                  1
                ),
              ],
              1
            ),
          ]),
          _c(
            "li",
            [
              _c("label", [_vm._v("介绍：")]),
              _c("el-input", {
                attrs: { type: "textarea", size: "small", rows: 4 },
                model: {
                  value: _vm.params.description,
                  callback: function ($$v) {
                    _vm.$set(_vm.params, "description", $$v)
                  },
                  expression: "params.description",
                },
              }),
            ],
            1
          ),
          _c(
            "li",
            [
              _c("label", [_vm._v("状态：")]),
              _c(
                "el-radio-group",
                {
                  model: {
                    value: _vm.params.status,
                    callback: function ($$v) {
                      _vm.$set(_vm.params, "status", $$v)
                    },
                    expression: "params.status",
                  },
                },
                [
                  _c("el-radio", { attrs: { label: 0 } }, [_vm._v("启用")]),
                  _c("el-radio", { attrs: { label: 1 } }, [_vm._v("停用")]),
                ],
                1
              ),
            ],
            1
          ),
        ]),
        _c(
          "div",
          { staticClass: "btns" },
          [
            _c(
              "el-button",
              { attrs: { size: "small" }, on: { click: _vm.handleCancel } },
              [_vm._v("取 消")]
            ),
            _c(
              "el-button",
              {
                attrs: { type: "primary", size: "small" },
                on: { click: _vm.handleConfirm },
              },
              [_vm._v("确 定")]
            ),
          ],
          1
        ),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("label", { staticClass: "common-tip" }, [
      _c("i", [_vm._v("*")]),
      _vm._v("租户编码："),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("label", { staticClass: "common-tip" }, [
      _c("i", [_vm._v("*")]),
      _vm._v("账号："),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("label", { staticClass: "common-tip" }, [
      _c("i", [_vm._v("*")]),
      _vm._v("姓名："),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("label", { staticClass: "common-tip" }, [
      _c("i", [_vm._v("*")]),
      _vm._v("权限："),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }