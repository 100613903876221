<template>
  <div class="js-common-wrap js-creater-user">
    <div class="js-common-head">
      <div>
        <el-button class="back-btn" @click="$goBack" icon="el-icon-arrow-left" size="small" type="primary" plain></el-button>
        <label class="label">{{ type?'修改用户':'创建用户' }}</label>
      </div>
    </div>
    <div class="js-common-content">
      <div class="js-creater-user-content">
        <ul>
          <li>
            <label>头像：</label>
            <el-upload
              class="avatar-uploader"
              action="#"
              :show-file-list="false"
              :auto-upload="false"
              :on-change="handleHttpRequest">
              <img class="image-url" v-if="params.avatar" :src="params.imageUrl">
              <div v-else class="icon">
                <img src="@/assets/images/13.png" alt="">
                <p>上传图片</p>
              </div>
            </el-upload>
          </li>
          <li>
            <label class="common-tip"><i>*</i>租户编码：</label>
            <el-input v-model="params.tenantCode" size="small" disabled></el-input>
          </li>
          <li>
            <label class="common-tip"><i>*</i>账号：</label>
            <el-input v-model="params.name" size="small"></el-input>
          </li>
          <li>
            <label class="common-tip"><i>*</i>姓名：</label>
            <el-input v-model="params.realName" size="small"></el-input>
          </li>
          <li>
            <label>密码：</label>
            <el-input v-model="params.password" size="small" show-password></el-input>
          </li>
          <li>
            <label class="common-tip"><i>*</i>权限：</label>
            <div>
              <el-select class="line" v-model="params.roleId" placeholder="请选择" size="small" clearable>
                <el-option v-for="item in options" :key="item.id" :label="item.name" :value="item.id"></el-option>
              </el-select>
              <p class="tip">通讯列表显示眼镜用户加普通用户；可查看可创建作业流程，可改自己创建的流程，可执行和上传作业；无法删除记录</p>
            </div>
          </li>
          <li>
            <label>联系方式：</label>
            <el-input v-model="params.mobile" size="small"></el-input>
          </li>
          <li>
            <label>组织：</label>
            <div class="flex1">
              <el-select class="half" v-model="params.deptParentId" placeholder="请选择" @change="deptChnage" size="small" clearable>
                <el-option v-for="item in deptArr" :key="item.id" :label="item.name" :value="item.id"></el-option>
              </el-select>
              <el-select class="select half" v-model="params.deptId" placeholder="请选择" size="small" clearable>
                <el-option v-for="sub in subDept" :key="sub.id" :label="sub.name" :value="sub.id"></el-option>
              </el-select>
            </div>
          </li>
          <li>
            <label>介绍：</label>
            <el-input v-model="params.description" type="textarea" size="small" :rows="4"></el-input>
          </li>
          <li>
            <label>状态：</label> 
            <el-radio-group v-model="params.status" >
              <el-radio :label="0">启用</el-radio>
              <el-radio :label="1">停用</el-radio>
            </el-radio-group>
          </li>
          <!-- <li v-if="!type"><el-checkbox v-model="checked">国标28181</el-checkbox></li> -->
        </ul>
        <div class="btns">
          <el-button @click="handleCancel" size="small">取 消</el-button>
          <el-button type="primary" @click="handleConfirm" size="small">确 定</el-button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
export default {
  name: '',
  data() {
    return {
      checked: false,

      options: [],
      currentDept: '',
      deptArr: [],
      currentSubDept: '',
      subDept: [],
      params: {
        avatar:'',
        tenantCode:'',
        name:'',
        realName:'',
        password:'',
        roleId:'',
        mobile:'',
        deptParentId:'',
        deptId:'',
        description:'',
        status:0
      },
      type:'',
      action:''
    }
  },
  computed:{
    ...mapGetters(['getUserInfo'])
  },
  watch: {
    params: {
      handler(val) {
        if (val.id) {
          this.$axios({
            method: 'get',
            url: `api/csp/mgr/v1/user/${val.id}`
          }).then((response)=>{
            this.params.imageUrl = response.data.data.fullAvatar;
          })
        }
      },
      deep: true,
      immediate: true,
    }
  },
  mounted() {
    // 转为Boolean值
    this.params = JSON.parse(this.$route.query.options)
    this.type = parseInt(this.$route.query.type)
    this.getDept()
    if(this.params.deptParentId){
      this.deptChnage()
    }
    this.getRole()
    if(!this.type){
      this.getTenantCode()
    }
  },
  methods: {
    // 获取租户编码
    getTenantCode(){
      this.params.tenantCode = this.getUserInfo.tenantCode
    },
    // 获取组织
    getDept(){
      this.$axios({
        method: 'get',
        url: `api/csp/mgr/v1/dept/child/1`
      }).then((response)=>{
        let data = response.data
        if(!data.status){
          this.deptArr = data.data
        }else{
          this.$message({
            type: 'error',
            message: data.msg,
            duration: 1000
          })
        }
      })
    },
    // 获取子组织
    deptChnage(){
      this.$axios({
        method: 'get',
        url: `api/csp/mgr/v1/dept/child/${this.params.deptParentId}`
      }).then((response)=>{
        let data = response.data
        if(!data.status){
          this.subDept = data.data
        }else{
          this.$message({
            type: 'error',
            message: data.msg,
            duration: 1000
          })
        }
      })
    },
    // 获取角色
    getRole(){
      this.$axios({
        method: 'get',
        url: `api/csp/mgr/v1/role/`
      }).then((response)=>{
        let data = response.data
        if(!data.status){
          this.options = data.data
        }else{
          this.$message({
            type: 'error',
            message: data.msg,
            duration: 1000
          })
        }
      })
    },
    handleConfirm(){
      let url = '', method = ''
      if(this.type){
        method = 'put'
        url = `api/csp/mgr/v1/user/${this.params.id}`
      }else{
        method = 'post'
        url = `api/csp/mgr/v1/user/`
      }
      this.$axios({
        method: method,
        url: url,
        data:{
          data:{
            avatar: this.action.file,
            tenantCode: this.params.tenantCode,
            name: this.params.name,
            realName: this.params.realName,
            password: this.params.password,
            roleId: this.params.roleId,
            mobile: this.params.mobile,
            deptParentId: this.params.deptParentId,
            deptId: this.params.deptId,
            description: this.params.description,
            status: this.params.status
          }
        }
      }).then((response)=>{
        let data = response.data
        if(!data.status){
          this.$goBack()
        }else{
          this.$message({
            type: 'error',
            message: data.msg,
            duration: 1000
          })
        }
      })
    },
    handleCancel(){
      this.$goBack()
    },
    // 获取上传文件地址
    getUploadDir(){
      return new Promise((resolve,reject)=>{
        this.$axios({
          method: 'get',
          url: 'api/csp/cos/v1/url/upload',
          params: {
            dirType: 'upload',
            suffix: 'jpeg'
          }
        }).then((response)=>{
          let data = response.data
          if(!data.status){
            this.action = data.data
          }else{
            this.$message({
              type: 'error',
              message: data.msg,
              duration: 1000
            })
          }
          resolve()
        }).catch(()=>{
          reject()
        })
      })
    },
    async handleHttpRequest(file){
      await this.getUploadDir()
      var xhr = new XMLHttpRequest()
      xhr.open('PUT', this.action.url, true)
      xhr.onload = (e) => {
        // console.log('上传成功', xhr.status, xhr.statusText)
        // console.log(this.action)
        this.params.imageUrl = this.action.url.split('?')[0]
        this.params.avatar = this.action.url.split('?')[0]
      }
      xhr.onerror = (e) => {
        // console.log('上传出错', xhr.status, xhr.statusText)
      }
      xhr.send(file.raw)
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/scss/createrUser.scss'
</style>
